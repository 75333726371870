import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import {
  Container,
  Grid,
  Box,
  Text,
  Flex,
  Image,
  Heading,
  Select,
} from "theme-ui"
import Layout from "../components/layout"
import { getPagePath } from "../utils/path"
import BlogTitle from "../components/blogTitle"
import { HelmetDatoCms } from "gatsby-source-datocms"
import FilterMetaTagDescription from "../utils/filterMetaTags"
import linkSwitch from "../utils/linkSwitch"
import countryIcon from "../images/event-country-icon.svg"
import locationIcon from "../images/event-location-icon.svg"

const EventPage = ({ data: { page, events } }) => {
  // console.log(events)
  const i18nPaths = page._allSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })

  const today = new Date()
  const [pastEvents, setPastEvents] = useState(
    events.nodes
      .filter(event => new Date(event.endDate) < today)
      .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))
  )

  const [futureEvents, setFutureEvents] = useState(
    events.nodes
      .filter(event => new Date(event.endDate) > today)
      .sort((a, b) => new Date(a.endDate) - new Date(b.endDate))
  )

  const [nextEvent, setNextEvent] = useState(futureEvents[0])
  const [otherEvents, setOtherEvents] = useState(futureEvents.slice(1))

  const [eventType, setEventType] = useState(
    futureEvents.length > 1 ? "future" : "past"
  )
  const handleEventTypeChange = event => {
    setEventType(event.target.value)
  }

  const [selectedCountry, setSelectedCountry] = useState("")
  const [filteredOtherEvents, setFilteredOtherEvents] = useState(otherEvents)
  const [filteredPastEvents, setFilteredPastEvents] = useState(pastEvents)

  useEffect(() => {
    const filterEvents = eventList => {
      if (selectedCountry === "") {
        return eventList
      } else {
        return eventList.filter(fair => fair.country === selectedCountry)
      }
    }

    setFilteredOtherEvents(filterEvents(otherEvents))
    setFilteredPastEvents(filterEvents(pastEvents))
  }, [selectedCountry])

  useEffect(() => {
    setSelectedCountry("")
  }, [eventType])

  return (
    <Layout
      locale={page.locale}
      i18nPaths={i18nPaths}
      theme={"dark"}
      hideMenu={true}
    >
      <HelmetDatoCms seo={FilterMetaTagDescription(page.seoMetaTags)}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <Container sx={{ mt: [8, "16rem"] }}>
        <BlogTitle page={page} />
        {nextEvent && <NextEvent nextEvent={nextEvent} page={page} />}
      </Container>
      <Container>
        <Flex sx={{ justifyContent: "flex-end", alignItems: "center" }}>
          <Box sx={{ "& > div": { marginRight: [3] } }}>
            <Select
              value={selectedCountry}
              onChange={e => setSelectedCountry(e.target.value)}
              sx={{
                p: 3,
                borderColor: "darkBackground",
                minWidth: "180px",
              }}
            >
              <option value="">All Countries</option>
              {[
                ...new Set(
                  (eventType === "future" ? otherEvents : pastEvents).map(
                    fair => fair.country
                  )
                ),
              ].map(country => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </Select>
          </Box>
          <Select
            value={eventType}
            onChange={handleEventTypeChange}
            sx={{ p: 3, borderColor: "darkBackground", minWidth: "180px" }}
          >
            <option value="future">Future Events</option>
            <option value="past">Past Events</option>
          </Select>
        </Flex>
      </Container>
      {eventType === "future" && filteredOtherEvents.length > 0 && (
        <EventContainer
          events={filteredOtherEvents}
          page={page}
          title="Events & Fairs"
        />
      )}
      {eventType === "past" && filteredPastEvents.length > 0 && (
        <EventContainer
          events={filteredPastEvents}
          page={page}
          title="Events & Fairs"
        />
      )}
    </Layout>
  )
}

export default EventPage

const NextEvent = ({ nextEvent, page }) => (
  <>
    <Heading
      as="h2"
      sx={{
        mb: [4],
        color: "dark",
        fontSize: [3],
        borderTop: "1px solid",
        borderColor: "dark",
        pt: 3,
      }}
    >
      Up Next
    </Heading>
    <Grid columns={[1, 1, 1, 2]} gap={0} sx={{ backgroundColor: "dark" }}>
      <Flex
        sx={{
          color: "light",
          padding: 4,
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <Text as="p" sx={{ textTransform: "uppercase", fontSize: [1] }}>
          {nextEvent.startDate === nextEvent.endDate
            ? nextEvent.startDate
            : `${nextEvent.startDate} - ${nextEvent.endDate}`}{" "}
          {nextEvent.hallBooth && (
            <Text
              sx={{ color: "secondary" }}
            >{`• ${nextEvent.hallBooth}`}</Text>
          )}
        </Text>
        <Text
          as="p"
          sx={{
            fontSize: [6],
            mt: 3,
            mb: 3,
            lineHeight: 1.2,
          }}
        >
          {nextEvent.name}
        </Text>
        {nextEvent.location && (
          <Flex sx={{ alignItems: "flex-end", mb: [2] }}>
            <Image src={locationIcon} sx={{ mr: [2] }} />
            <Text
              as="p"
              sx={{
                textTransform: "uppercase",
                fontSize: [1],
                lineHeight: 1,
                color: "secondary",
              }}
            >
              {nextEvent.location}
            </Text>
          </Flex>
        )}
        {nextEvent.country && (
          <Flex
            sx={{
              alignItems: "flex-end",
            }}
          >
            <Image src={countryIcon} sx={{ mr: [2] }} />
            <Text
              as="p"
              sx={{
                textTransform: "uppercase",
                fontSize: [1],
                lineHeight: 1,
                color: "secondary",
              }}
            >
              {nextEvent.country}
            </Text>
          </Flex>
        )}
        {nextEvent.link && (
          <Box sx={{ mt: 4, a: { color: "white" } }}>
            {linkSwitch(nextEvent.link, page.locale)}
          </Box>
        )}
      </Flex>
      <Box
        sx={{
          img: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
        }}
      >
        <Image src={nextEvent.image.url} />
      </Box>
    </Grid>
  </>
)

const EventContainer = ({ events, page, title }) => (
  <Container>
    <Heading
      as="h2"
      sx={{
        mb: [4],
        color: "dark",
        fontSize: [3],
        borderTop: "1px solid",
        borderColor: "dark",
        pt: 3,
      }}
    >
      {title}
    </Heading>
    <Grid columns={[1, 1, 1, 2]} gap={32}>
      {events.map(fair => (
        <Event fair={fair} page={page} key={fair.id} />
      ))}
    </Grid>
  </Container>
)

const Event = ({ fair, page }) => (
  <Grid columns={[1, 1, 2]} gap={0}>
    <Box
      sx={{
        border: "1px solid",
        borderColor: "#dedede",
        borderRight: "none",
        img: {
          width: "100%",
          height: "100%",
          objectFit: "contain",
        },
      }}
    >
      <Image src={fair.image.url} alt={fair.name} />
    </Box>
    <Box
      sx={{
        backgroundColor: "dark",
        color: "light",
        padding: 4,
      }}
    >
      <Text as="p" sx={{ textTransform: "uppercase", fontSize: [1] }}>
        {fair.startDate === fair.endDate
          ? fair.startDate
          : `${fair.startDate} - ${fair.endDate}`}
      </Text>
      {fair.hallBooth && (
        <Text
          as="p"
          sx={{
            textTransform: "uppercase",
            fontSize: [1],
            color: "secondary",
          }}
        >
          {fair.hallBooth}
        </Text>
      )}
      <Text
        as="p"
        sx={{
          fontSize: [4],
          pt: 3,
          mt: 3,
          mb: 3,
          borderTop: "1px solid white",
        }}
      >
        {fair.name}
      </Text>
      {fair.location && (
        <Flex sx={{ alignItems: "flex-end", mb: [2] }}>
          <Image src={locationIcon} sx={{ mr: [2] }} />
          <Text
            as="p"
            sx={{
              textTransform: "uppercase",
              fontSize: [1],
              lineHeight: 1,
              color: "secondary",
            }}
          >
            {fair.location}
          </Text>
        </Flex>
      )}
      {fair.country && (
        <Flex
          sx={{
            alignItems: "flex-end",
          }}
        >
          <Image src={countryIcon} sx={{ mr: [2] }} />
          <Text
            as="p"
            sx={{
              textTransform: "uppercase",
              fontSize: [1],
              lineHeight: 1,
              color: "secondary",
            }}
          >
            {fair.country}
          </Text>
        </Flex>
      )}
      {fair.link && (
        <Box sx={{ mt: 4, a: { color: "white" } }}>
          {linkSwitch(fair.link, page.locale)}
        </Box>
      )}
    </Box>
  </Grid>
)

export const query = graphql`
  query EventPageQuery($id: String!, $locale: String!) {
    page: datoCmsEventPage(id: { eq: $id }) {
      ...EventPageDetails
    }
    events: allDatoCmsEvent(
      sort: { fields: startDate, order: DESC }
      filter: { locale: { eq: $locale } }
    ) {
      nodes {
        id
        name
        startDate(locale: "en", formatString: "DD MMMM Y")
        endDate(locale: "en", formatString: "DD MMMM Y")
        country
        location
        hallBooth
        image {
          url
        }
        link {
          ... on DatoCmsExternalLink {
            id: originalId
            anchor
            url
          }
          ... on DatoCmsInternalLink {
            id: originalId
            anchor
            locale
            model {
              apiKey
            }
            link {
              ... on DatoCmsEventPage {
                ...EventPageDetails
              }
              ... on DatoCmsBlogPage {
                ...BlogDetails
              }
              ... on DatoCmsPage {
                ...PageDetails
                ...PageTreeParent
                ...AllSlugLocales
              }
              ... on DatoCmsArticle {
                ...ArticleDetails
                ...ArticleAllSlugLocales
              }
              ... on DatoCmsArticleCategory {
                ...ArticleCategoryDetails
                ...ArticleCategoryAllSlugLocales
              }
            }
          }
        }
      }
    }
    site: datoCmsSite {
      locales
    }
  }

  fragment EventPageDetails on DatoCmsEventPage {
    id
    locale
    title
    seoMetaTags {
      ...GatsbyDatoCmsSeoMetaTags
    }
    _allSlugLocales {
      value
      locale
    }
    model {
      apiKey
    }
  }
`
